const data = {
    textHousesPL: {
        headText: 'Całoroczne domki góralskie w Szczawnicy',
        navOffer: 'Oferta',
        navMap: 'Mapa',
        navGallery: 'Galeria',
        navContact: 'Kontakt',
        navInvite: 'Sprawdź też nasz domek!',
        navLink: '/house',
        welcoming: 'Proponujemy Państwu całoroczne domki góralskie do wynajęcia w samym sercu Pienin! Serdecznie zapraszamy!',
        offer11: 'zapraszamy 2-6 osób',
        offer12: 'dwie sypialnie na piętrze',
        offer13: 'kuchnia, łazienka',
        offer14: 'TV, WiFi',
        offer21: 'w domku kominek',
        offer22: 'miejsce zadaszone na samochód',
        offer23: 'na terenie posesji grill oraz drewniana huśtawka',
        offer31: 'pieski mile widziane – jednorazowa opłata za czworonoga wynosi 50 zł',
        offer32: 'teren ogrodzony',
        contactInvite: 'Zapraszamy do kontaktu:',
        contactName: 'Paweł Porębski',
        contactNumber: '+48 602 225 793',
        contactEmail: 'magporeb@gmail.com',
        facebook: 'Domki Szczawnica',
        footerInvite: 'Zapraszamy:',
        footerPhone: 'telefon: +48 602 225 793',
        footerEmail: 'e-mail: magporeb@gmail.com',
    },

    textHousesENG: {
        headText: 'All-year-round highlander houses in Szczawnica',
        navOffer: 'Offer',
        navMap: 'Map',
        navGallery: 'Gallery',
        navContact: 'Contact',
        navInvite: 'Check our 2nd offer!',
        navLink: '/house',
        welcoming: 'We are offering you cozy wooden cottages for rent in the polish mountains!',
        offer11: 'these are available for up to 6 persons',
        offer12: 'there are two bedrooms on the first floor',
        offer13: 'kitchen and bathroom downstairs',
        offer14: 'TV, WiFi connection',
        offer21: 'Would you like to spend chilly evening by the fireplace?',
        offer22: 'there is a shelter for your car',
        offer23: 'and a grill',
        offer31: 'You can come with your dog! One-time rate is 50 PLN',
        offer32: 'garden is fenced',
        contactInvite: 'Do not hesitate to contact us: ',
        contactName: 'Paweł Porębski',
        contactNumber: '+48 602 225 793',
        contactEmail: 'magporeb@gmail.com',
        facebook: 'For English speakers – please contact us via facebook message: Domki Szczawnica',
        footerInvite: 'Come and enjoy:',
        footerPhone: 'telephone: +48 602 225 793',
        footerEmail: 'e-mail: magporeb@gmail.com',
    },

    textHousePL: {
        head: 'CHATA w Szczawnicy',
        navOffer: 'Oferta',
        navMap: 'Mapa',
        navGallery: 'Galeria',
        navContact: 'Kontakt',
        navInvite: 'Sprawdź też nasze domki!',
        navLink: '/',
        welcoming: 'Proponujemy Państwu całoroczny wolnostojący dom do wynajęcia, dla maksymalnie 15 osób w samym sercu Pienin, nad Dunajcem. Serdecznie zapraszamy!',
        offer11: 'zapraszamy grupy do 15 osób',
        offer12: 'cztery pokoje na pierwszym piętrze',
        offer13: 'w każdym pokoju łazienka, balkon',
        offer14: 'TV, WiFi',
        offer21: 'na parterze kuchnia oraz sala kominkowa',
        offer22: 'parking dla gości',
        offer23: 'taras z miejscem na grilla',
        offer31: 'pieski mile widziane – jednorazowa opłata za czworonoga wynosi 50 zł',
        contactInvite: 'Zapraszamy do kontaktu:',
        contactName: 'Paweł Porębski',
        contactNumber: '+48 602 225 793',
        contactEmail: 'magporeb@gmail.com',
        facebook: 'Domek Szczawnica',
        footerInvite: 'Zapraszamy:',
        footerPhone: 'telefon: +48 602 225 793',
        footerEmail: 'e-mail: magporeb@gmail.com',
    },

    textHouseENG: {
        head: 'Całoroczne domki góralskie w Szczawnicy',
        navOffer: 'Offer',
        navMap: 'Map',
        navGallery: 'Gallery',
        navContact: 'Contact',
        navInvite: 'Check our 2nd offer!',
        navLink: '/',
        welcoming: 'We are offering you all-year-round detached house for 15 persons for rent. It is located in Pieniny by Dunajec river in Szczawnica.',
        offer11: 'it is available for up to 15 persons',
        offer12: 'there are four bedrooms on the first floor',
        offer13: 'there is a bathroom and a balcony  in each room',
        offer14: 'TV, WiFi connection',
        offer21: 'on the groundfloor there is a full equipped kitchen and a dining room with a fireplace',
        offer22: 'car park for the guests',
        offer23: 'also with a terrace with a barbecue spot',
        offer31: 'You can come with your dog! One-time rate is 50 PLN',
        contactInvite: 'Do not hesitate to contact us!',
        contactName: 'Paweł Porębski',
        contactNumber: '+48 602 225 793',
        contactEmail: 'magporeb@gmail.com',
        facebook: 'For English speakers – please contact us via facebook message: Domki Szczawnica',
        footerInvite: 'Come and enjoy:',
        footerPhone: 'telephone: +48 602 225 793',
        footerEmail: 'e-mail: magporeb@gmail.com',
    }
}

export default data
