import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'
import Overlay from 'pigeon-overlay'

const MapComponent = () => {
    return (
        <Map center={[49.427763, 20.444866]} zoom={12} twoFingerDrag={true}>
            <Marker anchor={[49.427763, 20.444866]} payload={1} onClick={({ event, anchor, payload }) => {}} />
        </Map>
    )
}

export default MapComponent