const Head = (props) => {
    const data = props.dataLang
    if(props.houses) {
        return (
            <div className="jumbotron text-center margin-bottom:0 head-image1">
            </div>
        )
    }
    else {
        return (
            <div className="jumbotron text-center margin-bottom:0 head-image2">
            </div>
        )
    }
}

export default Head
