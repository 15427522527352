import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import './ContentComponentCSS.css'
import dial from './pictures/dial.png'
import family from './pictures/rodzinka.png'
import bed from './pictures/bed.png'
import house from './pictures/domek.png'
import tv from './pictures/tv.png'
import fence from './pictures/fence.png'
import doge from './pictures/doge.png'
import chimney from './pictures/chimney.png'
import car from './pictures/car.png'
import grill from './pictures/grill.png'
import fb from './pictures/fb.png'
import email from './pictures/email.png'
import person from './pictures/person.png'
import blank from './pictures/blank.png'
import Gallery from "./GalleryComponent";
import MapComponent from "./MapComponent";

const OfferLine = (props) => {
    const {text, src} = props
    return (
        <div className='offer-row'>
            <img className='photo' src={src} alt='' />
            <p className='center-text'>{text}</p>
        </div>
    )
}

const LinkLine = (props) => {
    const {text, src} = props
    const facebookLink = (props.isHouse) ? "https://www.facebook.com/DomekSzczawnica" : "https://www.facebook.com/DomkiSzczawnica"
    return (
        <div className='offer-row'>
            <img className='photo' src={src} alt='' />
            <a href={facebookLink} className='center-text'>{text}</a>
        </div>
    )
}

const Content = (props) => {
    const history = useHistory()
    const handleOnClick = useCallback(() => history.push('/'), [history])
    const data = props.dataLang
    const pictures = props.pictures
    const galleryPictures = props.galleryPictures

    return (
    <div className="container margin-top:30px">
        <div className="row row-container" id='Offer'>
            <p className='welcome'>{data.welcoming}</p>
        </div>
        <div className="row row-container" id='Offer'>
            <div className="col-sm-5 offer">
                <OfferLine text={data.offer11} src={family}/>
                <OfferLine text={data.offer12} src={bed}/>
                <OfferLine text={data.offer13} src={house}/>
                <OfferLine text={data.offer14} src={tv}/>
            </div>
            <div className="col-sm-7 image-container">
                <img className='img-fluid' src={pictures[0]}/>
            </div>
        </div>
        <div className="row row-container flex-column-reverse flex-md-row">
            <div className="col-sm-7 image-container">
                <img className='img-fluid' src={pictures[1]}/>
            </div>
            <div className="col-sm-5 offer">
                <OfferLine text={data.offer21} src={chimney}/>
                <OfferLine text={data.offer22} src={car}/>
                <OfferLine text={data.offer23} src={grill}/>
            </div>
        </div>
        <div className="row row-container">
            <div className="col-sm-5 offer">
                <OfferLine text={data.offer31}
                           src={doge}/>
                {(!props.isHouse) ? <OfferLine text={data.offer32} src={fence}/> : null}
            </div>
            <div className="col-sm-7 image-container">
                <img className='img-fluid' src={pictures[2]}/>
            </div>
        </div>
        <div id='Gallery'>
            <Gallery pictures={galleryPictures}/>
        </div>
        <div className="row row-container">
            <div className="col-sm-6 map-container" id='Map'>
                <MapComponent/>
            </div>
            <div className="col-sm-6 offer" id='Contact'>
                <OfferLine text={data.contactInvite} src={blank}/>
                <OfferLine text={data.contactName} src={person}/>
                <OfferLine text={data.contactNumber} src={dial}/>
                <OfferLine text={data.contactEmail} src={email}/>
                <LinkLine isHouse={props.isHouse} text={data.facebook} src={fb}/>
            </div>
        </div>
    </div>
    )

}

export default Content
