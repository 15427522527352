import { Link } from 'react-scroll'
import { Toggle } from 'react-toggle-component'
import './NavbarComponentCSS.css'
const Navbar = (props) => {
    const data = props.dataLang
    const facebookLink = (props.isHouse) ? "https://www.facebook.com/DomekSzczawnica" : "https://www.facebook.com/DomkiSzczawnica"
    return (
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark bordering-navbar">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className='label'>
                <p>PL</p>
                <Toggle
                    leftBackgroundColor="tomato"
                    rightBackgroundColor="green"
                    borderColor="black"
                    knobColor="white"
                    name="toggle-3"
                    onToggle={(e) => props.toggleLang(e.target.checked)}
                />
                <p>ENG</p>
            </div>
            <div className="collapse navbar-collapse center-content" id="collapsibleNavbar">
                <Link className="navbar-brand noselect" activeClass="active" to="Offer" spy={true} smooth={true} duration={500}>
                    {data.navOffer}
                </Link>
                <Link className="navbar-brand noselect" activeClass="active" to="Map" spy={true} smooth={true} duration={500}>
                    {data.navMap}
                </Link>
                <Link className="navbar-brand noselect" activeClass="active" to="Gallery" spy={true} smooth={true} duration={500}>
                    {data.navGallery}
                </Link>
                <Link className="navbar-brand noselect" activeClass="active" to="Contact" spy={true} smooth={true} duration={500}>
                    {data.navContact}
                </Link>
                <a className="navbar-brand" href={facebookLink}>Facebook</a>
                <a className="navbar-brand go-to-second-offer-color" href={data.navLink}>{data.navInvite}</a>
            </div>
        </nav>
    )
}

export default Navbar
