import React from "react";

const Footer = (props) => {
    const data = props.dataLang
    const facebookLink = (props.isHouse) ? "https://www.facebook.com/DomekSzczawnica" : "https://www.facebook.com/DomkiSzczawnica"
    return (
        <div className="jumbotron text-center margin-bottom:0">
            <p>{data.footerInvite}</p>
            <p>{data.footerPhone}</p>
            <p>{data.footerEmail}</p>
            <p>Facebook:</p>

            <a href={facebookLink} className='center-text'>{data.facebook}</a>
         </div>
    )
}

export default Footer
