import { Carousel } from 'react-responsive-carousel'
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css'
import './GalleryComponentCSS.css'

const Gallery = (props) => {
    const galleryList = Array.from(props.pictures).map((elem) => (
            <div className='image-container'>
                <img className='gallery-image'  src={elem} />
            </div>
        ))

    return(
        <div className='row row-container'>
            <div className='col-sm-12'>
                <Carousel
                    emulateTouch={true}
                    dynamicHeight={true}

                >
                    {galleryList}
                </Carousel>
            </div>
        </div>
    )
}

export default Gallery
