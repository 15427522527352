import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom'

import './App.css';
import Navbar from './components/NavbarComponent'
import Head from "./components/HeadComponent";
import Content from "./components/ContentComponent";
import Footer from "./components/FooterComponent";
import data from './HousesText'
import {useEffect, useState} from "react";

import housesSquare1 from './components/pictures/houses/main/housesSquare1.jpg'
import housesSquare2 from './components/pictures/houses/main/housesSquare2.jpg'
import housesSquare3 from './components/pictures/houses/main/housesSquare3.jpg'

import houseSquare1 from './components/pictures/house/main/houseSquare1.jpg'
import houseSquare2 from './components/pictures/house/main/houseSquare2.jpg'
import houseSquare3 from './components/pictures/house/main/houseSquare3.jpg'

import chata0 from './components/pictures/house/gallery/chata0.jpg'
import chata1 from './components/pictures/house/gallery/chata1.jpg'
import chata2 from './components/pictures/house/gallery/chata2.jpg'
import chata3 from './components/pictures/house/gallery/chata3.jpg'
import chata4 from './components/pictures/house/gallery/chata4.jpg'
import chata5 from './components/pictures/house/gallery/chata5.jpg'
import chata6 from './components/pictures/house/gallery/chata6.jpg'
import chata7 from './components/pictures/house/gallery/chata7.jpg'
import chata8 from './components/pictures/house/gallery/chata8.jpg'
import chata9 from './components/pictures/house/gallery/chata9.jpg'
import chata10 from './components/pictures/house/gallery/chata10.jpg'
import chata11 from './components/pictures/house/gallery/chata11.jpg'
import chata12 from './components/pictures/house/gallery/chata12.jpg'

import domki0 from './components/pictures/houses/gallery/domki0.jpg'
import domki1 from './components/pictures/houses/gallery/domki1.jpg'
import domki2 from './components/pictures/houses/gallery/domki2.jpg'
import domki3 from './components/pictures/houses/gallery/domki3.jpg'
import domki4 from './components/pictures/houses/gallery/domki4.jpg'
import domki5 from './components/pictures/houses/gallery/domki5.jpg'
import domki6 from './components/pictures/houses/gallery/domki6.jpg'
import domki7 from './components/pictures/houses/gallery/domki7.jpg'
import domki8 from './components/pictures/houses/gallery/domki8.jpg'
import domki9 from './components/pictures/houses/gallery/domki9.jpg'
import domki10 from './components/pictures/houses/gallery/domki10.jpg'
import domki11 from './components/pictures/houses/gallery/domki11.jpg'
import domki12 from './components/pictures/houses/gallery/domki12.jpg'
import domki13 from './components/pictures/houses/gallery/domki13.jpg'
import domki14 from './components/pictures/houses/gallery/domki14.jpg'
import domki15 from './components/pictures/houses/gallery/domki15.jpg'


const Houses = () => {
    const langFunction = (status) => {
        if(status){
            return data.textHousesENG
        } else {
            return data.textHousesPL
        }
    }
    const [lang, setLang] = useState(langFunction(false))

    const pictures = [housesSquare1, housesSquare2, housesSquare3]
    const galleryPictures = [domki0, domki1, domki2, domki3, domki4,
        domki5, domki6, domki7, domki8, domki9, domki10, domki11, domki12,
        domki13, domki14, domki15]

    useEffect(() => {}, [lang])
    return (
        <div>
            <Head dataLang={lang} houses={true}/>
            <Navbar
                isHouse={false}
                dataLang={lang}
                toggleLang = {(event) => setLang(langFunction(event))}
            />
            <Content
                dataLang={lang}
                pictures = {pictures}
                galleryPictures = {galleryPictures}
                isHouse={false}
            />
            <Footer isHouse={false} dataLang={lang} />
        </div>
    )
}

const House = () => {
    const langFunction = (status) => {
        if(status){
            return data.textHouseENG
        } else {
            return data.textHousePL
        }
    }
    const [lang, setLang] = useState(langFunction(false))

    const pictures = [houseSquare1, houseSquare2, houseSquare3]
    const galleryPictures = [chata0, chata1, chata2, chata3,chata4, chata5,
        chata6, chata7, chata8, chata9, chata10, chata11, chata12]

    useEffect(() => {}, [lang])
    return (
        <div>
            <Head dataLang={lang} houses={false}/>
            <Navbar isHouse={true} dataLang={lang} toggleLang = {(event) => setLang(langFunction(event))}/>
            <Content
                dataLang={lang}
                pictures = {pictures}
                galleryPictures = {galleryPictures}
                isHouse={true}
            />
            <Footer isHouse={true} dataLang={lang} />
        </div>
    )
}

function App() {
  return (
      <Router>
          <Switch>
              <Route exact path='/' component={Houses} />
              <Route path='/house' component={House} />
          </Switch>
      </Router>
  );
}

export default App;
